<template>
    <div>
        <Pane />
        <a-select
            style="width: 200px;margin-right: 20px;margin-left: 10px;margin-top: 10px;" @change="getInfo()"
            v-model="deptId"
            show-search
            :filter-option="$selectFilterOption"
        >
            <a-select-option
            v-for="item in organizationList"
            :key="item.id"
            :value="item.id"
            >{{ item.uniqueName }}</a-select-option
            >
        </a-select>
        <a-radio-group style="margin-right: 20px;" v-if="deptId=='1221122277233463296'" v-model="ifWis" button-style="solid" @change="typeChange">
            <a-radio-button :value="false">
                设计类
            </a-radio-button>
            <a-radio-button :value="true">
                智慧类
            </a-radio-button>
        </a-radio-group>
        <a-card class="container">
            <a-form :label-col="{ span: 10 }" :wrapper-col="{ span: 12 }" :colon="false" :form="form">
                <div style="font-weight: bold;font-size: 16px;margin-bottom: 10px;">部门月度回款数据(万元)</div>
                <a-row v-for="item in detail">
                    <div style="margin-left: 33px;">
                        {{ item.month}}月
                    </div>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="总额">
                            <a-input v-if="ifWis" type="number" v-model="item.wisdomPaymentAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input v-else type="number" v-model="item.paymentAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="4" :md="4" :sm="12">
                        <a-form-item label="净额">
                            <a-input v-if="ifWis" type="number" v-model="item.wisdomPaymentNetAmount" placeholder="请输入" style="width: 180px"></a-input>
                            <a-input v-else type="number" v-model="item.paymentNetAmount" placeholder="请输入" style="width: 180px"></a-input>
                        </a-form-item>
                    </a-col>
                </a-row>
                <div class="center">
                    <a-space>
                        <a-button @click="$close($route.path)">关闭</a-button>
                        <a-button type="primary" :loading="loading" @click="handleSubmit">保存</a-button>
                    </a-space>
                </div>
            </a-form>
        </a-card>
    </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import request from "@/api/request";
import organization from "@/mixins/organization";
function save(data) {
    return request({
        url: "project-service/dsContract/updateDeptMonth",
        method: "post",
        data
    });
}
function getDetail(year,deptId) {
  return request({
    url: "project-service/dsContract/qryDeptMonth/" + year + '/' + deptId,
    method: "post"
  });
}
export default {
    name: "technicalQualityScoreAdd",
    mixins: [organization],
    computed: {
        ...mapGetters("setting", ["findDataDict"]),
        ...mapGetters("auth", ["isHr"]),
        typeList() {
            return this.findDataDict("bid_target_calc_method");
        },
    },
    data() {
        return {
            form: this.$form.createForm(this),
            loading: false,
            detail: {},
            yearList: [],
            deptId: '',
            year: '',
            type: '',
            ifWis: false,
        };
    },
    mounted() {
        const { query } = this.$route;
        this.getYearList()
        const { deptId, year, ifWis } = query || {};
        this.deptId =  deptId
        this.year = year
        if(ifWis == 'true'){
            this.ifWis = true
        }
        this.getInfo()
        
    },
    methods: {
        typeChange(data) {
            this.ifWis = data.target.value
            this.getInfo()
        },
        getInfo() {
            getDetail(this.year, this.deptId).then(res => {
                this.detail = res;
            });
        },
        getYearList() {
            const now = new Date().getFullYear()
            for (let index = 2021; index <= now; index++) {
                this.yearList.push(
                    {
                        name: index,
                        value: index
                    }
                )
            }
        },
        hanldeSave() {

        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.loading = true;
                    save(this.detail)
                        .then(() => {
                            this.$close(this.$route.path);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        }
    },
};
</script>
  
  
<style lang="less" scoped>
.container {
    padding: 12px;
}

.center {
    margin-top: 80px;
    margin-bottom: 80px;
}
</style>